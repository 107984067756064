.suppression-banner {
  @include pxRem(top, 78);
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: var(--p-z-9);;
  .Polaris-Banner__Button {
    background-color: map-get($color, white) !important;
    &:hover {
      background-color: var(--p-surface-warning-subdued-hovered) !important;
    }
  }
}

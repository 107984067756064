.special-laws {
  .text-description {
    color: map-get($map: $color, $key: blue-e);
  }

  &.operator-page {
    .header-section {
      @include pxRem(padding, 20);
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px map-get($color, grey-h);
    }
    .body-section {
      @include pxRem(padding, 20);
    }
  }
}

.Polaris-Card {
  @include pxRem(margin-bottom, 20);
}

.Polaris-IndexTable__TableCell {
  vertical-align: middle;
}

.Polaris-IndexTable__TableHeading {
  font-weight: 600;
  vertical-align: middle;
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
    @include pxRem(padding, 8 16 8 0);
  }
}

.selectable-no-checkbox-table {
  .Polaris-IndexTable__TableCell--first,
  .Polaris-IndexTable__TableHeading--first {
    display: none;
  }
  .Polaris-IndexTable__TableCell,
  .Polaris-IndexTable__TableHeading--second {
    @include pxRem(padding, 8 16);
  }
  .Polaris-IndexTable__StickyTableHeader--isSticky {
    .Polaris-IndexTable__TableHeading {
      .Polaris-Stack {
        .Polaris-Stack__Item {
          &:first-child {
            display: none;
          }
          &:nth-child(2) {
            .Polaris-IndexTable--stickyTableHeadingSecondScrolling {
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.Polaris-IndexTable__StickyTableHeader--isSticky {
  top: 56px;
}

.Polaris-EmptyState--fullWidth .Polaris-EmptyState__Details {
  max-width: 100% !important;
}

.Polaris-TextField__Input {
  font-family: $font-body;
}

.auth-card {
  @include pxRem(max-width, 400);
  @include pxRem(padding, 60 30 20);
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: map-get($map: $color, $key: white);
  box-shadow: 0 0 1px 0 #00000040;
  box-shadow: 0 2px 1px 0 #0000000d;
  border-radius: 8px;
  color: map-get($map: $color, $key: title-black);
  .header {
    .logo {
      @include pxRem(width, 192);
      @include pxRem(height, 40);
      @include pxRem(margin-bottom, 30);
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .content {
    .title {
      @include pxRem(margin-bottom, 25);
      font-size: map-get($map: $font-size, $key: xl);
      font-weight: 400;
    }

    .description {
      @include pxRem(margin-bottom, 40);
      font-size: map-get($map: $font-size, $key: normal);
      color: map-get($map: $color, $key: grey-g);
    }

    .login-card__password {
      position: relative;
      .Polaris-TextField__Input {
        @include pxRem(padding-right, 45);
      }
    }
    .btn-login {
      @include pxRem(margin-top, 20);
    }
    .Polaris-Button--iconOnly {
      position: absolute;
      top: 13px;
      margin: 0;
      right: 1px;
      width: 50px;
      padding: 0;
      border: 0;
      z-index: 100;
      background-color: transparent;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      box-shadow: none;
    }
    .reset-msg {
      @include pxRem(margin-top, 10);
      display: flex;
      align-items: center;
      font-size: map-get($map: $font-size, $key: normal);
      line-height: 20px;
      color: map-get($map: $color, $key: text-msg);
      .Polaris-Icon {
        @include pxRem(max-width, 20);
        @include pxRem(margin, 0 15 0 0);
        width: 100%;
      }
    }
  }
  .submit-btn-wrap {
    @include pxRem(margin, 20 0);
  }
}

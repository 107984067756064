.wrap-orders-delay {
  .wrap-orderDate-status {
    @include pxRem(margin-bottom, 15);
    .Polaris-Button {
      @include pxRem(margin-top, 24);
    }
  }
  .wrap-orderDate {
    display: flex;
    .Polaris-TextField {
      @include pxRem(min-width, 260);
    }
    .icon-tilde {
      @include pxRem(margin-top, 28);
      @include pxRem(padding, 4);
    }
  }
  .wrap-group-btn {
    @include pxRem(margin-top, 24);
  }
}

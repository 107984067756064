#gallery-wrap {
  &.loading {
    &::after {
      content: 'Loading...';
    }
  }

  .img-list {
    list-style: none;
    grid-gap: 1rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
  }

  .list-item {
    border: solid 0.5px map-get($color, grey-h);
    border-radius: 5px;
    &.active {
      border: solid 2px map-get($color, primary);
      transform: scale(0.8);
    }
  }

  .img-wrap {
    height: 100%;
    width: 100%;
    position: relative;
    padding-bottom: 100%;
  }

  .img-content {
    display: block;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}
#idText {
  border: 1px solid grey;
  padding: 4px;
}
.richtext-error {
  .tox {
    .tox-editor-container {
      border: 1px solid map-get($map: $color, $key: border-err);
      .tox-toolbar__primary {
        background: none;
        border-bottom: 1px solid map-get($map: $color, $key: border-err);
      }
      .tox-edit-area__iframe {
        background-color: map-get($map: $color, $key: bg-err);
      }
    }
    &.tox-tinymce {
      border: none;
    }
  }
}
.tox {
  &.tox-tinymce--disabled {
    .tox-toolbar__primary {
      background-color: map-get($map: $color, $key: gray);
    }
    .tox-edit-area__iframe {
      background-color: map-get($map: $color, $key: gray);
    }
  }
  .tox-button {
    @include pxRem(font-size, map-get($font-size, normal !important));
    background-color: map-get($color, primary) !important;
    border-color: map-get($color, primary) !important;
    font-weight: 500 !important;
  }
  .tox-button--naked {
    background-color: transparent !important;
    border: 0 !important;
  }
  .tox-button--secondary {
    background-color: map-get($color, white) !important;
    border-color: map-get($color, j) !important;
  }
}

.tox-tinymce-aux {
  z-index: 20 !important;
}

@media screen and (max-width: 1440px) {
  .tox-tinymce {
    width: auto !important;
  }
}

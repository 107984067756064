.performers-page {
  .search-group-btn {
    margin-bottom: var(--p-space-2);
  }
  .empty-section {
    padding: var(--p-space-3);
  }
  .Polaris-Image--isLoading {
    background-color: transparent;
  }
}

.orders-list-page {
  .filter-wrap {
    @include pxRem(padding, 10 16);
    border-bottom: solid 1px map-get($color, grey-h);
  }
  // .Polaris-Tabs {
  //   .Polaris-Tabs__TabContainer {
  //     &:nth-last-child(2) {
  //       flex: 1;
  //       .Polaris-Tabs__Title {
  //         &::before {
  //           display: none;
  //         }
  //         .wrap-btn-exportCsv {
  //           display: flex;
  //           justify-content: flex-end;
  //         }
  //       }
  //     }
  //   }
  // }
}

.order-page {
  .not-shipped-list {
    .list-item {
      display: grid;
      grid-template-columns: 3fr 1fr 1fr;
      align-items: center;
    }
    .prd-name {
      @include pxRem(padding-left, 20);
      display: inline-block;
      text-decoration: underline !important;
    }
  }

  .paid-info-list {
    .list-item {
      @include pxRem(padding-bottom, 10);
      display: grid;
      grid-template-columns: 1fr 1fr 2fr;
      justify-items: start;
    }
    .amount {
      width: 100%;
      text-align: end;
    }
  }
  .paid-total {
    display: flex;
    justify-content: space-between;
  }

  .shipping-address-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    word-break: break-word;
  }

  .thumbnail-wrap {
    position: relative;
    .badge-number {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(50%, -50%);
      .Polaris-Badge {
        @include pxRem(padding, 2 6);
      }
    }
  }

  .refund-price-group {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: 8.75rem 1fr;

    .price-list {
      margin-left: auto;
    }
  }
}

.order-refund-page {
  .list-item {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    .info-wrap {
      display: grid;
      grid-template-columns: 2.5rem auto;
      align-items: flex-start;
    }
    .amount-wrap {
      align-items: center;
      display: grid;
      grid-template-columns: auto minmax(5.625rem, auto);
      .Polaris-TextField__Input {
        @include pxRem(width, 30);
      }
    }
  }
}

.delivery-modal {
  .delivery-company-wrap {
    .label {
      @include pxRem(margin-bottom, 4);
    }
    .Polaris-Button {
      width: 100%;
      &__Content {
        justify-content: space-between;
      }
    }
  }
}

.refund-request-page {
  .filter-wrap {
    @include pxRem(padding, 10 16);
    border-bottom: solid 1px map-get($color, grey-h);

    .Polaris-Filters {
      width: 50%;
    }
  }
}

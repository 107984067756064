$header-height: 66px;

.media-preview-section {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 515;
  width: 100vw;
  height: 100vh;
  background: map-get($color, bg-black);
  overflow-y: scroll;
  overflow-x: hidden;

  .header {
    @include pxRem(padding, 0 15);
    border-bottom: solid 1px #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $header-height;
    width: 100%;
    color: map-get($color, white);
    .close-btn {
      svg {
        fill: map-get($color, white);
      }
      &.disable {
        svg {
          fill: map-get($color, grey-g);
        }
      }
    }
  }

  .body {
    color: map-get($color, white);
    display: flex;
    justify-content: space-between;
    height: calc(100vh - $header-height - 6px);
    .content {
      flex: 1;

      .img-section {
        @include pxRem(padding, 40);
        position: relative;
        height: 100%;
        width: 100%;
        .fabric-canvas,
        .spinner-wrap {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .canvas-resize {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
      }
    }
    .sidebar {
      @include pxRem(width, 320);
      background: map-get($color, black-a);
      .img-name {
        font-weight: 700;
        word-break: break-all;
      }
      .img-size {
        font-weight: 700;
        color: map-get($color, white);
      }
    }
    .title {
      @include pxRem(padding, 16 12);
      font-weight: bold;
    }
    .img-title {
      font-weight: 700;
    }

    .resize-form {
      @include pxRem(padding, 30 12 50);
      background: map-get($color, grey-n);
      .Polaris-TextField__Backdrop {
        background: map-get($color, black);
      }
      .Polaris-TextField__Input,
      .Polaris-TextField__Suffix {
        color: map-get($color, white);
        caret-color: map-get($color, white);
      }
    }
    .iframe {
      width: 100%;
      height: 100%;
    }
  }
}

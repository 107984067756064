.term-of-use {
  .Polaris-Card {
    .Polaris-TextContainer {
      @include pxRem(height, 385);
      @include pxRem(padding, 7);
      border: 1px solid map-get($map: $color, $key: grey-i);
      border-radius: 4px;
      overflow: auto;
    }

    .Polaris-Choice {
      @include pxRem(margin-bottom, 20);
      width: 100%;
      justify-content: center;
    }
  }
}
.payment-method {
  .padding-between-date {
    @include pxRem(padding-right, 10);
    @include pxRem(padding-left, 10);
  }
}

.icon-tooltip {
  // @include pxRem(left, 110);
  // position: absolute;
  // top: 10;

  .Polaris-Icon {
    &:hover {
      cursor: pointer;
      .Polaris-Icon__Svg {
        fill: map-get($color, green-a);
      }
    }
  }
}

.sidemenu {
  @include pxRem(padding-top, 16);
  // @include pxRem(top, 56);
  // position: fixed;
  // height: calc(100% - 56px);
  border-right: solid 1px map-get($color, grey-b);
  .menu-list {
    @include pxRem(width, 240);
    padding: 0;

    .list-item {
      @include pxRem(margin-bottom, 2);
      position: relative;

      &.bottom {
        @include pxRem(bottom, 10);
        position: absolute;
        right: 0;
        left: 0;
      }
    }

    .js-active {
      .item-content {
        @include pxRem(border-radius, 4);
        background: map-get($color, grey-c);
        color: map-get($color, primary);
      }
      .Polaris-Icon--colorBase svg {
        fill: map-get($color, primary);
      }
      &::before {
        @include pxRem(width, 3);
        @include pxRem(border-top-right-radius, 5);
        @include pxRem(border-bottom-right-radius, 5);
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: 100%;
        background-color: map-get($color, primary);
      }
    }

    .item-content {
      @include pxRem(margin, 3 8);
      @include pxRem(padding, 2 12);
      @include pxRem(font-size, 14);
      font-weight: 700;
      color: map-get($color, grey-e);
      transition: all 0.1s;
      cursor: pointer;
      &:hover {
        background: map-get($color, grey-d);
      }

      &.disabled {
        color: map-get($color, grey-h);
        .Polaris-Icon--colorBase svg {
          fill: map-get($color, grey-h);
        }
      }
    }

    .Polaris-Icon {
      @include pxRem(margin, 0 9 0 0);
    }
  }

  .sub-menu-collapse {
    padding: 0;
    .item-content {
      @include pxRem(padding-left, 41);
    }
    &.show {
      @include pxRem(height, 28);
      transition: 0.3s all;
      overflow: visible;
      opacity: 1;
    }
    &.hide {
      height: 0;
      transition: 0.3s all;
      overflow: hidden;
      opacity: 0;
    }
  }
}

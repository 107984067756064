.custom-drop-image {
  &.has-file {
    .file-list {
      list-style: none;
      grid-gap: 1rem;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      list-style: none;
      margin: 0;
      padding: 0;
      position: relative;
    }

    .list-item {
      // @include pxRem(min-height, 185);
      border: solid 0.5px map-get($color, grey-h);
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      &:first-child {
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;
      }
      &.variant {
        &:first-child {
          grid-column: 1;
          grid-row: 1;
        }
      }
      .overlay {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        left: 0;
        border-radius: 4px;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: 0.3s;
        cursor: pointer;
      }

      .checkbox {
        @include pxRem(top, 3);
        @include pxRem(left, 6);
        position: absolute;
        z-index: 20;
        opacity: 0;
        &.not-editable {
          display: none;
        }
      }

      .preview {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        z-index: 10;
        .Polaris-Icon svg {
          fill: map-get($color, white);
        }
      }

      &:hover {
        .overlay,
        .checkbox,
        .preview {
          opacity: 1;
        }
      }

      &.checked {
        .checkbox {
          opacity: 1;
        }
        .overlay {
          opacity: 1;
          background-color: rgba(255, 255, 255, 0.5);
        }
        .preview {
          display: none;
        }
      }

      .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
      }
    }

    .item-wrap {
      width: 100%;
      height: 100%;
      position: relative;
      padding-bottom: 100%;
      .Polaris-Labelled--hidden {
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
      }
      .Polaris-DropZone {
        height: 100%;
      }
      .play-img {
        @include pxRem(height, 30);
        @include pxRem(width, 30);
        @include pxRem(bottom, 10);
        @include pxRem(right, 10);
        position: absolute;
      }
    }

    .img-content {
      display: block;
      margin: auto;
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }

    .file-content {
      @include pxRem(padding, 15);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 100%;

      .title {
        @include pxRem(font-size, 10);
        color: map-get($color, grey-g);
        display: inline-block;
        width: 100%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .err-wrap {
    @include pxRem(margin-top, 4);
  }

  .delete-wrap {
    @include pxRem(top, 20);
    @include pxRem(right, 20);
    position: absolute;

    &.p-base {
      top: 0;
      right: 0;
    }
  }
  .file-upload-section {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .button-group {
      @include pxRem(margin-bottom, 5);
      display: flex;
      .action-title {
        @include pxRem(margin-right, 10);
      }

      &.column {
        .action-title {
          @include pxRem(margin, 0 0 10);
        }
        flex-direction: column;
        align-items: center;
      }
      &.disabled {
        .Polaris-Button,
        .Polaris-Link {
          color: map-get($color, text-msg);
          cursor: unset;
        }
        .Polaris-Button {
          background-color: map-get($color, gray);
        }
        .Polaris-Link {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .action-hint {
      @include pxRem(font-size, 12)
    }
    .add-link-btn {
      display: inline-flex;
      font-weight: 600;
    }

    .action-title {
      button {
        border: 0;
        box-shadow: none;
        background: map-get($color, blue-f);
        color: map-get($color, blue-c);
        &:hover {
          color: map-get($color, blue-g);
        }
      }
    }
  }
  .Polaris-DropZone {
    z-index: 1;
  }
}

.custom-drop-file {
  .list-item {
    border: 1px solid map-get($color, grey-l);
    border-bottom: none;
    
    .item-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: 0.3s all;
      cursor: pointer;
      &:hover {
        background-color: map-get($color, grey-c);
      }

      &.uploading {
        position: relative;
        pointer-events: none;

        &:hover {
          background-color: red;
        }
        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: rgba(1, 1, 1, 0.1);
        }
      }
    }

    .file-content {
      display: flex;
      align-items: center;
      flex: 1;
      @include pxRem(padding, 12 0);
    }

    .name {
      @include pxRem(padding-left, 10);
    }

    .icon-wrap {
      cursor: pointer;
      @include pxRem(height, 15);
      @include pxRem(margin-right, 10);

      .spinner-wrap {
        @include pxRem(margin-top, -4);
      }
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &:nth-last-child(2) {
      border-bottom: 1px solid map-get($color, grey-l);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.topbar-setting {
  display: flex;
  background-color: white;
  border-bottom: 2px solid #e9e9eb;
  justify-content: space-between;
  align-items: center;
  padding-top: 9px;
  padding-bottom: 9px;
  .item {
    display: flex;
    align-items: center;
  }
  &--title {
    margin-left: 18px;
  }
}

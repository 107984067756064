.freeze-modal-content {
  @include pxRem(padding, 20);
  @include pxRem(font-size, map-get($font-size, xl));
  background: map-get($color, white);
  position: relative;
  z-index: 1;
  border-radius: 10px;
  display: flex;
}

.Polaris-Modal-Header {
  .Polaris-InlineError {
    align-items: center;
    font-weight: 700;
  }
}

.err-msg {
  @include pxRem(margin-top, 8);
  display: flex;
  align-items: center;
  color: map-get($map: $color, $key: err-msg-login);
  font-size: map-get($map: $font-size, $key: sm);
  line-height: 20px;
  .Polaris-Icon {
    @include pxRem(max-width, 20);
    @include pxRem(margin, 0 16 0 0);
    width: 100%;
  }
}

.Polaris-InlineError {
  @include pxRem(font-size, map-get($font-size, normal));  
}

.operator-list-bank-accounts {
  .label-search {
    @include pxRem(width, 100);
    @include pxRem(margin-right, 5);
  }
  .input-search {
    flex: 1;
  }
  .spinner-wrap {
    @include pxRem(padding, 100);
    display: flex;
    justify-content: center;
  }
  .hyper-link {
    text-decoration: underline;
    color: map-get($map: $color, $key: blue-b);
  }
  .Polaris-IndexTable__TableRow {
    td:nth-child(2) {
      @include pxRem(min-width, 200);
    }
    td:nth-child(3) {
      @include pxRem(min-width, 200);
    }
  }
}

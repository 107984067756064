.profile-upload {
  position: relative;
  @include pxRem(max-width, 210);
  @include pxRem(margin, 50 auto);
  .profile-edit {
    position: absolute;
    @include pxRem(bottom, 10);
    @include pxRem(right, 22);
    z-index: 1;
    input {
      display: none;
    }
    .wrap-btn-upload {
      @include pxRem(width, 34);
      @include pxRem(height, 34);
      display: inline-block;
      border-radius: 100%;
      background: map-get($color, white);
      border: 1px solid transparent;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        background: map-get($color, grey);
        border-color: map-get($color, grey-a);
      }
      .Polaris-Button {
        @include pxRem(top, 8);
        position: absolute;
        left: 0;
        right: 0;
      }
    }
  }
  canvas {
    border-radius: 100%;
  }
  .wrap-profile-preview {
    @include pxRem(width, 200);
    @include pxRem(height, 200);
    position: relative;
    margin: 0 auto;

    .profile-preview {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }

    &.has-overlay {
      &::after {
        content: "";
        background-color: rgba(255, 255, 255, 0.5);
        height: 100%;
        width: 100%;
        z-index: 1;
        position: absolute;
        right: 0;
      }
    }
  }
  .profile-note {
    @include pxRem(margin, 10 -100);
  }
}
.crop-container {
  @include pxRem(width, 350);
  @include pxRem(height, 350);
}

.performer-detail-page {
  .wrap-text {
    .Polaris-Subheading {
      text-transform: unset;
    }
  }
  .paypay-table {
    width: 60%;
  }
  .Polaris-Card__SectionHeader {
    .Polaris-Subheading {
      @include pxRem(font-size, 16)
    }
  }
}

.page-header {
  .Polaris-TopBar {
    .Polaris-TopBar__ContextControl {
      @include pxRem(width, 750);
      @include pxRem(margin-left, 20);
      display: flex;
      align-items: center;
      .Polaris-TextStyle--variationStrong {
        width: max-content;
        white-space: nowrap;
      }
    }
  }
}
.Polaris-Popover__Content {
  @include pxRem(max-width, 500px);
}
.wrap-popup-list-pj {
  @include pxRem(min-width, 500px);
  .Polaris-ActionList__Item {
    @include pxRem(padding-top, 20px);
    @include pxRem(padding-bottom, 20px);
  }
  .Polaris-ActionList__Prefix {
    width: auto;
  }
}

.Polaris-IndexTable__StickyTableHeader--isSticky {
  top: 56px;
}

.txt-prewarp {
  white-space: pre-wrap;
}
.txt-left {
  text-align: left;
}
.txt-right {
  text-align: right;
}
.txt-center {
  text-align: center;
}
.txt-end {
  text-align: end;
}
.no-wrap {
  white-space: nowrap;
}
.txt-bold {
  font-weight: 700;
}
.txt-demi {
  font-weight: 600;
}
.txt-uppercase {
  text-transform: uppercase;
}
.txt-capitalize {
  text-transform: capitalize;
}
.sub-text {
  @include pxRem(font-size, 12);
}
.txt-success {
  color: map-get($map: $color, $key: green-b);
}
.txt-negative {
  color: map-get($map: $color, $key: red-a);
}

.txt-primary {
  color: map-get($color, primary);
}

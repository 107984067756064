$chatboxHeight: 45vh;
.msg-list-page {
  .red-btn-flag-wrap {
    .Polaris-Button {
      .Polaris-Icon__Svg {
        fill: red;
        stroke: map-get($map: $color, $key: grey-h);
      }
    }
  }
  .white-btn-flag-wrap {
    .Polaris-Button {
      .Polaris-Icon__Svg {
        fill: white;
        stroke: map-get($map: $color, $key: grey-h);
      }
    }
  }
  .Polaris-IndexTable--statusSuccess {
    background-color: map-get($map: $color, $key: blue-d);
    .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
      background-color: map-get($map: $color, $key: blue-d);
    }
  }
  .Polaris-Image--isLoading {
    background-color: transparent !important;
  }
}
.msg-detail-page {
  position: relative;

  .header-section {
    @include pxRem(padding, 20);
    @include pxRem(min-height, 76);
    display: flex;
  }

  .reload-btn {
    @include pxRem(right, 40);
    @include pxRem(top, 20);
    position: absolute;
  }

  .chat-box-section {
    @include pxRem(padding, 0 20 10);
  }
  .chat-box-wrap {
    position: relative;
    .loadmore-loading {
      @include pxRem(top, 10);
      position: absolute;
      right: 50%;
      transform: translateX(-50%);
    }
  }
  .chat-box {
    @include pxRem(padding, 20);
    height: $chatboxHeight;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    .Polaris-Card {
      @include pxRem(margin-top, 20);
      @include pxRem(padding, 10 20);
      margin-bottom: 0;
      overflow-y: auto;
      margin-bottom: 0;
    }
  }

  .msg-content {
    @include pxRem(padding, 10 20);
    display: inline-block;
    box-shadow: 0 0 0.3125rem rgba(23, 24, 24, 0.05),
      0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    width: fit-content;
  }

  .msg-group {
    @include pxRem(margin-bottom, 10);
    width: 80%;
    word-break: break-all;
    &.sent {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .msg-content {
        background-color: map-get($color, blue-d);
        word-break: break-word;
      }
    }
    &.received {
      .msg-content {
        display: flex;
      }
    }
  }

  .input-section {
    @include pxRem(padding, 20);
    border-top: solid 1px map-get($color, grey-b);
  }
  .input-wrap {
    @include pxRem(margin-left, 15);
    position: relative;
    width: 100%;
    flex-direction: column;
    .err-msg {
      @include pxRem(top, -30);
      position: absolute;
      left: 0;
    }
    .imgs-wrap {
      @include pxRem(top, 3);
      @include pxRem(left, 1);
      @include pxRem(right, 1);
      @include pxRem(height, 90);
      @include pxRem(border-radius, 4);
      @include pxRem(padding, 10);
      background-color: map-get($color, white);
      position: absolute;
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
    }
    .counter {
      color: map-get($color, text-msg)
    }
  }

  .initial-loading-section {
    @include pxRem(padding, 0 20 30);
    .Polaris-Card {
      height: $chatboxHeight;
      margin-bottom: 0;
    }
    .spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  .checking-loading {
    @include pxRem(margin-left, 10);
    .Polaris-Spinner {
      display: flex;
      align-items: center;
    }
  }

  .send-msg-btn {
    align-self: flex-end;
    .Polaris-Button {
      width: max-content;
    }
  }
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.zoom-image {
  display: block;
  position: relative;
  padding-top: 60%;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}

.search-conversation {
  @include pxRem(width, 500);
}

@include respond-below(wide) {
  .search-conversation {
    @include pxRem(max-width, 300);
  }
}

.page-body {
  @include pxRem(padding-top, 22);
}

.container {
  @include pxRem(max-width, 870);
  @include pxRem(padding, 0 20);
  margin: auto;
}

.fluid-container {
  @include pxRem(padding, 0 20);
  margin-right: auto;
  margin-left: auto;
}


%clr {
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}
%flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
%flex-column {
  display: flex;
  flex-direction: column;
}
%flex-row {
  display: flex;
  flex-direction: row;
}
%flex-collumn-centered {
  display: flex;
  align-items: center;
  flex-direction: column;
}
%flex-row-btw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.text-container {
  @include pxRem(padding, 10);
  border-radius: 4px;
  
  &.dark {
    border: 1px solid map-get($map: $color, $key: grey-i);
    background: map-get($map: $color, $key: bg-grey);
  }
  
  &.light {
    background: map-get($map: $color, $key: white);
    border: 1px solid map-get($map: $color, $key: grey-b);
  }

  &.container-scroll {
    @include pxRem(max-height, 494);
    overflow: auto;
  }
}

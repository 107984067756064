.variant-module {
  .option-section {
    .options-list {
      .option-item {
        border-top: solid 1px map-get($color, grey-b);
      }

      .edit-btn {
        .Polaris-Button--sizeSlim {
          min-width: fit-content;
          height: fit-content;
        }
        .Polaris-Stack {
          width: 100%;
          word-break: break-word;
        }
      }
      &.done {
        .item-wrap {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }
      }
    }
    .option-name-wrap {
      .combobox-wrap {
        @include pxRem(margin-right, 10);
        width: 100%;
      }
    }
    .option-value-wrap {
      .text-field-wrap {
        @include pxRem(margin-right, 10);
        width: 100%;
      }
      .Polaris-InlineError {
        @include pxRem(padding-right, 46);
        word-break: break-all;
      }
    }

    .option-value-wrap {
      .list-item {
        @include pxRem(margin-bottom, 5);
      }
    }

    .footer {
      @include pxRem(padding, 20);
      border-top: solid 1px map-get($color, grey-b);
    }
  }

  .variant-section {
    .wrap-btn {
      word-break: break-word;
    }
    .image-cell {
      @include pxRem(width, 60);
      @include pxRem(height, 60);
      .Polaris-Thumbnail--sizeMedium {
        width: 100%;
      }
    }
    .title-cell {
      @include pxRem(width, 200);
      word-break: break-all;
      &.deleted {
        font-weight: 500;
        text-decoration: line-through;
        text-decoration-color: map-get($color, grey-g);
      }
      span {
        white-space: normal;
      }
    }
    .price-cell {
      @include pxRem(width, 150);
      .Polaris-Icon--colorCritical svg {
        fill: map-get($color, err-msg-login);
      }
    }
    .stock-cell {
      @include pxRem(width, 100);
    }
  }

  .trash-icon {
    button {
      border: 0;
      box-shadow: none;
      svg {
        fill: map-get($color, grey-m);
        transition: 0.1s all;
      }
      &:hover {
        background: none;
        svg {
          fill: map-get($color, grey-f);
        }
      }

      &:focus {
        background: none;
      }
    }
  }
}
.list-price-variant {
  .item-price {
    @include pxRem(padding, 16 0);
    border-top: 1px solid map-get($map: $color, $key: grey-k);
  }
  .text-box-price {
    @include pxRem(padding-right, 0);
  }
}
.list-qty-variant {
  .item-quantity {
    @include pxRem(padding, 16 0);
    border-top: 1px solid map-get($map: $color, $key: grey-k);
    .text-box-qty {
      @include pxRem(padding-right, 0);
    }
  }
}

.form-group {
  @include pxRem(padding-top, 10);
  @include pxRem(padding-bottom, 10);
  position: relative;
  .Polaris-TextField__Input {
    @include pxRem(padding-top, 8);
    @include pxRem(padding-bottom, 8);
  }

  .Polaris-Select {
    @include pxRem(padding-top, 3);
    @include pxRem(padding-bottom, 3);
  }

  .Polaris-InlineError {
    align-items: center;
  }
}

.form {
  .sub-text {
    @include pxRem(font-size, 12);
    @include pxRem(margin-top, 4);
    white-space: pre-wrap;

    a {
      text-decoration: underline !important;
      color: map-get($color, blue-b);
    }

    .Polaris-Icon {
      color: map-get($color, blue-a) !important;
    }
    #inner-html {
      @include pxRem(font-size, 12);
    }
  }
}
.input-w-2 {
  @include pxRem(width, 200);
  @include pxRem(padding-right, 20);
}
.input-w-2-6 {
  @include pxRem(width, 260);
  @include pxRem(padding-right, 20);
}
.input-w-3 {
  @include pxRem(width, 300);
  @include pxRem(padding-right, 20);
}
.input-w-5 {
  @include pxRem(width, 500);
}
.input-w-4 {
  @include pxRem(width, 400);
  @include pxRem(padding-right, 20);
}

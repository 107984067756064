// .btn {
//   @include pxRem(padding, 8 16);
//   @include pxRem(border-radius, 5);
//   border-width: 0;
//   border-color: transparent;
//   font-size: map-get($font-size, normal);
//   cursor: pointer;
// }

// .btn-custom {
//   &.btn-danger {
//     background: map-get($color, btn-danger);
//     color: map-get($color, white);
//     box-shadow: 0px 1px 0px 0px #00000014;

//     &:hover {
//       background: map-get($color, btn-danger-hover);
//     }

//     &:active {
//       background: map-get($color, btn-danger-active);
//     }
//   }
// }
.no-border {
  .Polaris-Button--iconOnly {
    background: transparent;
    border: 0;
    box-shadow: none;
    outline: 0;
  }
}

.btn-add-more {
  background-color: map-get($map: $color, $key: white);
  border: none;
  border-radius: 3px;
  color: map-get($color, $key: blue-a);
  width: 100%;
  &:hover {
    color: map-get($color, blue-c);
    cursor: pointer;
  }
  &:focus {
    background-color: map-get($map: $color, $key: grey-a);
  }
  .icon {
    .Polaris-Icon__Svg {
      fill: map-get($color, blue-a);
    }
  }
}

.popover-activator {
  &.err {
    button {
      border-color: map-get($color, err-msg-login);
      background-color: map-get($color, bg-err);
    }
  }
}
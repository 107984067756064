.peformer-prds-list-page {
  .card-header {
    @include pxRem(padding, 30 20);
    position: relative;

    .err-msg {
      @include pxRem(left, 20);
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .table-filter-section {
    @include pxRem(padding, 10 16);
    border-top: solid 1px map-get($color, grey-h);
    border-bottom: solid 1px map-get($color, grey-h);
    display: flex;
    align-items: flex-start;
    .filter-wrap {
      flex: 1;
    }
  }
  .spinner-wrap {
    @include pxRem(padding, 100);
    display: flex;
    justify-content: center;
  }

  .page-action {
    @include pxRem(margin-bottom, 20);
    display: flex;
    justify-content: space-between;
    .wrap-pj {
      .Polaris-Button {
        @include pxRem(padding-top, 4px);
        @include pxRem(padding-bottom, 4px);
        @include pxRem(padding-left, 19px);
        @include pxRem(max-width, 500px);
        @include pxRem(min-width, 500px);
        background-color: map-get($map: $color, $key: grey-a);
        .Polaris-Button__Content {
          justify-content: space-between;
          .Polaris-Button__Icon {
            @include pxRem(padding-right, 10px);
          }
          .Polaris-Button__Text {
            @include pxRem(min-width, 395px);
            text-align: left;
          }
        }
      }
    }
  }
  tr > td:nth-child(3) {
    a {
      color: map-get($color, title-black);
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
  .Polaris-IndexTable--statusSubdued {
    display: none;
  }
  .Polaris-IndexTable__Table {
    .Polaris-IndexTable__TableRow {
      .Polaris-Thumbnail--sizeSmall {
        backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
      }
    }
  }
}
.empty-project {
  .empty-project-content {
    @include pxRem(line-height, 28);
    font-size: map-get($map: $font-size, $key: lg);
  }
}
.card-wrap-tabs {
  .Polaris-Tabs__Wrapper {
    @include pxRem(top, 56);
    position: sticky;
    background-color: map-get($map: $color, $key: white);
    z-index: 402;
  }
}
.wrap-tabs-second {
  .Polaris-Tabs__Wrapper {
    background-color: map-get($map: $color, $key: white);
  }
  .wrap-special-laws {
    .Polaris-Card {
      @include pxRem(border-radius, 0 0 8 8);
    }
  }
}
.operator-prds-list-page {
  .title-bulk-action-wrap {
    @include pxRem(top, 109);
    position: sticky;
    background-color: map-get($map: $color, $key: white);
    z-index: 402;
    border-bottom: solid 1px map-get($map: $color, $key: grey-h);
  }
  .admin-url-wrap {
    @include pxRem(padding, 20 20 0);
    display: flex;
    justify-content: space-between;
    .title {
      @include pxRem(max-width, 500);
    }
    .kintone-link {
      @include pxRem(margin-right, 10);
      display: flex;
      align-items: center;

      .Polaris-Heading {
        text-decoration: underline;
      }
    }
  }

  .suspend-txt {
    font-size: map-get($map: $font-size, $key: normal);
    justify-content: center;
  }

  .select-section {
    @include pxRem(padding, 10 20);
    border-bottom: solid 1px map-get($color, grey-h);
  }

  .bulk-actions-section {
    @include pxRem(padding, 10 20);
    border-top: solid 1px map-get($color, grey-h);
    .wrap {
      display: flex;
      align-items: center;
    }
  }

  .prds-list {
    .header {
      @include pxRem(margin-bottom, 10);
      @include pxRem(padding, 20 20 0);

      &-actions {
        @include pxRem(margin-top, 10);
        display: flex;
        justify-content: space-between;
      }
    }

    .body {
      @include pxRem(padding, 10 20 20);
    }

    .prd-item {
      &.selected {
        background-color: map-get($map: $color, $key: blue-d);
      }
      border-bottom: solid 1px map-get($color, grey-h);
      &:last-child {
        border: 0;
      }
      .highlight-border {
        label:not(.Polaris-Choice) {
          color: var(--p-icon-warning);
        }
      }
    }

    .price-stock-gr {
      @include pxRem(margin-bottom, 10);
      display: grid;
      grid-template-columns: 1fr 1fr;
      @include pxRem(grid-gap, 10);
    }
  }

  .title-group {
    label {
      @include pxRem(min-width, 45);
    }
  }

  .img-list {
    @include pxRem(grid-gap, 10);
    list-style: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    .list-item {
      border: solid 0.5px map-get($color, grey-h);
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      &:first-child {
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;
      }
    }

    .item-wrap {
      width: 100%;
      height: 100%;
      position: relative;
      padding-bottom: 100%;
    }

    .img-content {
      display: block;
      margin: auto;
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
  .wrap-btn-description {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .wrap-shippingDate,
  .wrap-description {
    .Polaris-Card {
      word-break: break-word;
    }
    .Polaris-Card__Section {
      @include pxRem(max-height, 130);
      overflow: auto;
    }
  }
  .stock-tag-group {
    .Polaris-Stack__Item {
      flex: 1;
    }
    .stock {
      .Polaris-Card__Section {
        text-align: start;
      }
    }
  }
  .group-tag,
  .prohibited {
    .Polaris-Card__Section {
      @include pxRem(height, 60);
      display: flex;
      flex-wrap: wrap;
      overflow: auto;
      .Polaris-Tag {
        @include pxRem(margin, 0 10 8 0);
      }
    }
  }
  .accessory {
    .Polaris-Card__Section {
      @include pxRem(padding, 16);
    }
  }
  .price-prohibited-group,
  .accessory-prohibited-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
  }

  .Polaris-Choice {
    display: flex;
  }
  .form-label {
    @include pxRem(margin-bottom, 10);
    display: inline-block;
  }

  .scroll-top {
    @include pxRem(bottom, 40);
    @include pxRem(right, 40);
    position: fixed;
  }
}
.line-clamp #inner-html {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.field-memo {
  .Polaris-TextField--multiline > .Polaris-TextField__Input {
    overflow: hidden;
  }
}
.shippingNote-modal {
  .shippingNote {
    width: 70%;
  }
}

.btn-dropdown {
  .Polaris-Button {
    @include pxRem(width, 250);
    .Polaris-Button__Content {
      justify-content: space-between;
    }
    .Polaris-Button__Text {
      @include ellipsis-special();
    }
  }
}
.dropdown-title-template {
  .Polaris-ActionList__Actions {
    @include pxRem(width, 250);
  }
  .Polaris-Badge {
    @include pxRem(font-size, 11);
    width: max-content;
  }
  .Polaris-ActionList__Text {
    @include ellipsis-special();
  }
  .Polaris-ActionList__Suffix {
    @include pxRem(margin-left, 5);
  }
}
.comment-modal-actions {
  .Polaris-Button {
    @include pxRem(margin-right, 8);
    @include pxRem(margin-top, 8);
  }
}
.comment-tooltip {
  @include pxRem(width, 200);
}

// #toast {
//   @include pxRem(bottom, 30);
//   position: fixed;
//   left: 50%;
//   transform: translateX(-50%);
//   z-index: 100;
// }
// .toast {
//   @include pxRem(line-height, 28);
//   width: 100% !important;
//   border-radius: 7px !important;
//   font-family: $font-body;
//   font-weight: 400;
//   .toast-content {
//     @include pxRem(border-radius, 7);
//     background-color: map-get($color, grey-f);
//     color: white;
//     .toast-body {
//       @include pxRem(padding, 16 22 16 26);
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       font-size: map-get($map: $font-size, $key: lg);
//     }
//     button {
//       @include pxRem(margin-left, 43);
//       padding: 0;
//       background: none !important;
//       border: none !important;
//       box-shadow: none !important;
//     }
//   }
// }

#toast {
  @include pxRem(bottom, 30);
  @include pxRem(max-width, 500);
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 520;
  .toast {
    background-color: map-get($color, black);
    color: map-get($color, white);
    display: flex;
    align-items: center;
    padding: 16px 5px 16px 16px;
    border-radius: 7px;
    margin-top: 2px;

    &.error {
      background-color: map-get($color, error-bg);
    }
  }
  .toast-content {
    @include pxRem(margin, 0 15);
    white-space: pre-line;
    text-align: center
  }

  .Polaris-Button--iconOnly {
    &:hover {
      background: transparent;
    }
    .Polaris-Icon {
      height: 15px;
      width: 15px;
    }
    svg {
      fill: white;
    }
  }
}
.maintenance-page {
  @include pxRem(max-width, 760);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  color: map-get($color, bg-black);
  text-align: center;

  .Polaris-Card {
    @include pxRem(padding, 0 140 40);
  }
  .header {
    .logo {
      @include pxRem(width, 190);
      @include pxRem(height, 40);
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .content {
    .title {
      font-size: map-get($font-size, xl);
    }
  }
}
.operator-maintenance-page {
  @include pxRem(padding, 0 20);
  margin: auto;
  color: map-get($color, bg-black);
  text-align: center;
  .header {
    .logo {
      @include pxRem(width, 190);
      @include pxRem(height, 40);
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .content {
    .title {
      font-size: map-get($font-size, xl);
    }
  }
}

.operator-all-prds-page {
  tr > td {
    a {
      color: map-get($color, title-black);
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
  .spinner-wrap {
    @include pxRem(padding, 100);
    display: flex;
    justify-content: center;
  }
  .page-action {
    @include pxRem(margin-bottom, 20);
    display: flex;
    justify-content: flex-end;
  }

  .search-field-allproducts {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .search-auto {
      // @include pxRem(width, 700);
      display: flex;
    }
    .search-by {
      .Polaris-ChoiceList__Choices {
        display: flex;
        .Polaris-ChoiceList__ChoiceItem {
          @include pxRem(margin-left, 20);
        }
      }
    }
  }

  &.header {
    @include pxRem(margin-left, 240);
    .Polaris-Stack__Item:first-child {
      @include pxRem(min-width, 210);
    }
  }
  .Polaris-Card__Section {
    &+ .Polaris-Card__Section { 
      padding-top: 0;
      border-top: unset;
    }
  }
}

.search-by-options {
  .Polaris-Button {
    @include pxRem(min-width, 240);
  }
  .Polaris-Button__Content {
    justify-content: space-between;
  }
}

.import-tags-modal {
  .file-name-wrap {
    height: 100%;
  }
  .file-name-txt {
    font-weight: bold;
    text-align: center;
  }
  .file-upload-section {
    height: 100%;
    .Polaris-DropZone-FileUpload__Action {
      @include pxRem(padding, 6 12);
      font-size: map-get($font-size, normal);
      font-weight: normal;
    }
  }
}
// @include respond-below(largest) {
//   .search-field-allproducts {
//     .search-auto {
//       @include pxRem(max-width, 400);
//     }
//   }
// }

.input-title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 44px;
  letter-spacing: 0.5px;
  color: #1d1f20;
}

.error-message {
  @include pxRem(font-size, 12);
  @include pxRem(margin-top, 2);
  color: map-get($color, err-msg);
  font-weight: bold;
}

.Polaris-TextField {
  &__Backdrop {
    border-top-color: map-get($color, grey-k);
  }
}

.input-label {
  @include pxRem(margin-bottom, 4);
  display: inline-block;
}

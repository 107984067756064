.access-denied-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .content {
    @include pxRem(margin, 10 0);
    color: map-get($map: $color, $key: grey-h);
  }
}

.performer-email,
.change-password {
  .wrap-pwd {
    position: relative;
    .Polaris-TextField__Input {
      @include pxRem(padding-right, 45);
    }
    .Polaris-Button--iconOnly {
      @include pxRem(width, 50);
      @include pxRem(top, 24);
      @include pxRem(right, 1);
      position: absolute;
      margin: 0;
      padding: 0;
      border: 0;
      z-index: 100;
      background-color: transparent;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      box-shadow: none;
    }
  }
  .btn-update-email {
    .Polaris-Button {
      width: 100%;
    }
  }
}
.form-change-email,
.form-change-password {
  @include pxRem(width, 340);
}
.wrap-border-content {
  @include pxRem(padding, 10);
  border: 1px solid map-get($map: $color, $key: grey-i);
  border-radius: 5px;
  .grid-container {
    display: grid;
    grid-template-columns: 100px auto;
    line-height: 2;
    .grid-item {
      display: flex;
      justify-content: space-between;
    }
  }
}
.nowrap {
  white-space: nowrap;
}

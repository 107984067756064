.preview-page {
  letter-spacing: initial;
  .separate-line {
    border-bottom: solid 1px map-get($color, shopify-grey-c);
  }
  .section-title {
    @include pxRem(font-size, 22);
    font-weight: bold;
    line-height: 1.5;
  }
  position: fixed;
  inset: 0;
  z-index: 999;
  background-color: map-get($color, white);
  .preview {
    @include pxRem(padding-top, 20);
    width: 100%;
    height: 100%;
    overflow: scroll;
  }
  .product-sticky {
    position: sticky;
    top: 0;
  }
  .preview-container {
    @include pxRem(max-width, 1144);
    @include pxRem(padding, 0 12);
    margin: 0 auto;
  }
  .preview-row {
    @include pxRem(margin, 0 -12);
  }
  .breadcrumb {
    @include pxRem(font-size, 12);
    &.preview-container {
      @include pxRem(margin-bottom, 24);
    }
    .breadcrumb-item {
      &:not(:last-child) {
        &::after {
          @include pxRem(font-size, 14);
          @include pxRem(margin, 0 8);
          content: "/";
        }
      }
      &:last-child {
        color: map-get($color, shopify-grey-a)
      }
    }
  }
  .gallery-description {
    @include pxRem(padding, 0 12);
    max-width: percentage(7 / 12);
    flex: 0 0 percentage(7 / 12);
  }
  .product-gallery {
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .selected-img {
      @include pxRem(border-radius, 8);
      position: relative;
      background-color: #F7F7F7;
      overflow: hidden;
      width: 100%;
      padding-top: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        position: absolute;
        inset: 0;
        object-fit: contain;
        margin: auto;
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .img-list {
      @include pxRem(margin-left, -5);
      @include pxRem(margin-right, -5);
      flex-wrap: wrap;
    }
    .img-item {
      @include pxRem(border-radius, 8);
      @include pxRem(width, 120);
      @include pxRem(padding, 5);
      flex: 0 0 20%;
      overflow: hidden;
      cursor: pointer;
      img {
        @include pxRem(height, 68);
        @include pxRem(border-radius, 8);
        width: 100%;
        object-fit: cover;
        vertical-align: top;
        &.is-selected {
          border: solid 1px map-get($color, black);
        }
      }
    }
  }
  .product-description {
    .description-section {
      @include pxRem(padding, 40 0 48);
      @include pxRem(font-size, 16);
      p {
        @include pxRem(font-size, 16);
      }
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .product-info {
    @include pxRem(padding, 0 12 0 44);
    max-width: percentage(5 / 12);
    flex: 0 0 percentage(5 / 12);
    height: auto;
    background-color: map-get($color, white);
    line-height: 1.5;
    color: map-get($color, shopify-text-black);
    .product-title {
      @include pxRem(font-size, 26);
      font-weight: bold;
    }
    .product-price {
      @include pxRem(font-size, 22);
      font-weight: bold;
    }
    .shipping-date {
      font-size: map-get($font-size, sm);
    }
    .shipping-note {
      font-size: map-get($font-size, sm);
      color: map-get($color, shopify-grey-a);
    }
    .age-checkbox {
      .Polaris-Choice {
        align-items: center;
        pointer-events: none;
      }
      .Polaris-Choice__Control {
        @include pxRem(width, 25);
        @include pxRem(height, 25);
      }
      .Polaris-Checkbox__Backdrop {
        @include pxRem(border-width, 1);
        &:hover {
          cursor: unset;
        }
      }
      .Polaris-Choice__Label {
        @include pxRem(font-size, 15);
        &:hover {
          cursor: unset;
        }
      }
    }
    .variants {
      .variant {
        @include pxRem(max-width, 352);
        @include pxRem(padding, 11 16);
        @include pxRem(border-radius, 8);
        border: 1px solid map-get($color, shopify-grey-b);
        justify-content: space-between;
        span {
          @include pxRem(font-size, 16);
        }
        .Polaris-Icon {
          margin: unset;
        }
        .option-value {
          @include pxRem(max-width, 220);
          display: inline-block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          vertical-align: top;
        }
      }
    }
    .quantity-selector {
      display: inline-flex;
      align-items: center;
      .selector-wrapper {
        @include pxRem(width, 32);
        @include pxRem(height, 32);
        border: 1px solid map-get($color, shopify-grey-b);
        border-radius: 50%;
        &.selector-minus {
          opacity: 0.3;
        }
      }
      .quantity {
        @include pxRem(width, 35);
        @include pxRem(font-size, 16);
      }
    }
    .actions-wrapper {
      .buy-btn {
        @include pxRem(border-radius, 8);
        @include pxRem(padding, 12 111);
        @include pxRem(font-size, 16);
        @include pxRem(width, 352);
        background-color: map-get($color, blue-h);
        color: map-get($color, white);
        font-weight: bold;
      }
      .sold-out-btn {
        color: map-get($color, shopify-grey-a);
        background: map-get($color, shopify-grey-d);
        cursor: not-allowed;
      }
      .favorite-btn {
        @include pxRem(border-radius, 8);
        @include pxRem(padding, 0 17);
        border: 1px solid map-get($color, shopify-grey-c);
      }
    }
    .feedback-wrapper {
      text-decoration: underline;
    }
  }
  .custom-inner-html {
    [class] {
      all: unset;
    }
  }
  #inner-html {
    * {
      line-height: 1.65;
    }
    h1, h2, h3, h4, h5, h6 {
      word-break: break-word;
    }
    h1, h2, h3, h4, h5, h6, p {
      margin-top: 0;
      &:last-child {
        margin: 0;
      }
    }
    img {
      max-width: 100%;
      height: auto;
      border-style: none;
      vertical-align: top;
    }
    svg:not(:root) {
      overflow: hidden;
    }
    ul,
    ol {
      margin: 0;
      padding: 0;
      list-style-position: inside;
    }
    pre {
      overflow: auto;
    }
    code,
    kbd,
    pre,
    samp {
      font-family: monospace, monospace;
      font-size: 16px;
    }
    button,
    input,
    optgroup,
    select,
    textarea {
      color: inherit;
      font: inherit;
      margin: 0;
    }
    button,
    input[type=submit] {
      padding: 0;
      overflow: visible;
      background: none;
      border: none;
      border-radius: 0;
      -webkit-appearance: none;
    }
    button,
    select {
      text-transform: none;
    }
    button,
    html input[type=button],
    input[type=reset],
    input[type=submit] {
      -webkit-appearance: button;
      cursor: pointer;
    }
    button[disabled],
    html input[disabled] {
      cursor: default;
    }
    button::-moz-focus-inner,
    input::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
    input {
      line-height: normal;
      border-radius: 0;
    }
    input[type=checkbox],
    input[type=radio] {
      box-sizing: border-box;
      padding: 0;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      height: auto;
    }
    input[type=search] {
      -webkit-appearance: none;
      box-sizing: content-box;
    }
    input[type=search]::-webkit-search-cancel-button,
    input[type=search]::-webkit-search-decoration {
      -webkit-appearance: none;
    }
    input::-moz-placeholder, textarea::-moz-placeholder {
      color: inherit;
    }
    input:-ms-input-placeholder, textarea:-ms-input-placeholder {
      color: inherit;
    }
    input::placeholder,
    textarea::placeholder {
      color: inherit;
    }
    fieldset {
      border: 1px solid #c0c0c0;
      margin: 0 2px;
      padding: 6px 10px 12px;
    }
    legend {
      border: 0;
      padding: 0;
    }
    textarea {
      overflow: auto;
    }
    optgroup {
      font-weight: bold;
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }
    td,
    th {
      padding: 0;
    }
  }
}
.Polaris-PositionedOverlay {
  z-index: 999;
}

.setting-menu {
  @include pxRem(margin-right, 20);

  .menu-list {
    @include pxRem(width, 269);
    padding: 0;

    .list-item {
      @include pxRem(margin-bottom, 2);
      position: relative;
    }
    .disabled {
      pointer-events: none;
      opacity: 0.6;
    }
    .js-active {
      .item-content {
        color: map-get($color, primary);
      }
      .Polaris-Icon--colorBase svg {
        fill: map-get($color, primary);
      }
      &::before {
        @include pxRem(left, 2);
        @include pxRem(width, 3);
        @include pxRem(border-top-right-radius, 5);
        @include pxRem(border-bottom-right-radius, 5);
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 80%;
        background-color: map-get($color, primary);
      }
    }

    .item-content {
      @include pxRem(height, 41);
      @include pxRem(padding, 10 20);
      font-size: map-get($font-size, normal);
      background-color: map-get($color, white);
      font-weight: 700;
      color: map-get($map: $color, $key: grey-e);
      transition: all 0.1s;
      &:hover {
        background: map-get($map: $color, $key: grey-d);
      }
      .hidden-icon {
        @include pxRem(width, 20);
      }
    }

    .Polaris-Icon {
      @include pxRem(margin, 0 15 0 0);
    }
  }
}

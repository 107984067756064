.product-page {
  .txt-notification {
    color: map-get($map: $color, $key: blue-e);
  }
  .product-status {
    .Polaris-Badge {
      width: max-content;
    }
  }
  .Polaris-InlineError {
    width: 100%;
    word-break: break-all;
  }
  .highlight-border {
    .Polaris-Card {
      box-shadow: var(--p-surface-warning) 0px 0px 8px 0px;
      background: var(--p-surface-warning-subdued);
    }
    .btn-add-more, 
    .Polaris-IndexTable__TableRow:not(.Polaris-IndexTable__TableRow--selected):not(.Polaris-IndexTable__TableRow--hovered), 
    .Polaris-IndexTable__ScrollBarContainer,
    .Polaris-IndexTable__BulkActionsWrapper {
      background: var(--p-surface-warning-subdued);
    }
  }
  .status-history {
    @include pxRem(max-height, 240);
    overflow-y: scroll;
    .Polaris-IndexTable {
      overflow: unset;
    }
    .Polaris-IndexTable__TableHeading,
    .Polaris-IndexTable__TableCell {
      position: static;
      width: 50%;
      text-align: left;
    }
    .Polaris-IndexTable__TableRow .Polaris-IndexTable__TableCell:last-child,
    tr th:last-child {
      @include pxRem(padding-left, 50);
    }
    .Polaris-IndexTable-ScrollContainer {
      overflow: unset;
    }
    .Polaris-IndexTable__ScrollBarContainer,
    .Polaris-IndexTable__StickyTableHeader {
      display: none;
    }
    thead {
      position: sticky;
      top: 0;
      z-index: 22;
      border-bottom: 1px solid;
      background: map-get($color, white);
    }
  }
}
.Polaris-Frame-ContextualSaveBar__Contents.Polaris-Frame-ContextualSaveBar--fullWidth {
  @include pxRem(padding, 20);
}
.wrap-card-tags {
  .Polaris-Card {
    background-color: map-get($map: $color, $key: blue-d);
  }
}
.wrap-btn-add-tag {
  @include pxRem(margin, 0 8);
  @include pxRem(padding, 7 3);
  display: flex;
  border-radius: 5px;
  &:hover {
    background-color: map-get($map: $color, $key: grey-d);
    cursor: pointer;
  }
  .Polaris-Button {
    width: 100%;
    justify-content: flex-start;
  }
  .Polaris-Icon {
    @include pxRem(width, 16);
    @include pxRem(height, 16);
  }
}
.tags-list {
  .Polaris-Icon {
    @include pxRem(width, 16);
    @include pxRem(height, 16);
  }
}
.section-body-modal {
  @include pxRem(height, 340);
  .Polaris-Modal-Section {
    min-height: 100%;
  }
}

.shippingDate {
  .shippingNote {
    width: 55%;
  }
  .Polaris-Button {
    @include pxRem(width, 60);
  }
  .Polaris-Stack__Item:last-child {
    @include pxRem(margin-top, 10);
  }
}

.prohibited {
  .err-msg {
    @include pxRem(font-size, 14);
  }
}

.disable-section {
  position: relative;
  cursor: not-allowed;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: map-get($color, grey-h );
    opacity: .4;
    border-radius: 8px;
  }
  
  &.highlight-border {
    .Polaris-Card {
      box-shadow: unset;
      background: unset;
    }
  }
}

.prd-features {
  .features-desc {
    color: map-get($color, grey-e);
  }
  .hint-txt {
    @include pxRem(padding, 0 6);
    background: map-get($color, yellow-a);
    color: map-get($color, white);
    border: 1px solid transparent;
    border-radius: 5px;
  }
  .show-hint-btn {
    padding: 0;
    background: none;
    border: none;
    color: map-get($color, grey-e);
    font-size: map-get($map: $font-size, $key: normal);
    text-decoration: underline;
    cursor: pointer;
  }
}

.hint-modal {
  .hint-modal-desc {
    @include pxRem(line-height, 44);
    .title {
      font-size: map-get($map: $font-size, $key: xl);
    }
    .content {
      font-size: map-get($map: $font-size, $key: lg);
      .highlight-content {
        color: map-get($color, yellow-a);
      }
    }
  }
  img {
    width: 100%;
  }
}

.setting-page {
  background-color: map-get($color, grey-a);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  .section-body {
    @include pxRem(margin-top, 20);
    display: flex;
    justify-content: center;
  }
  .Polaris-Page {
    width: 100%;
    margin: 0;
    padding: 0;
    max-width: unset;
    .Polaris-Page__Content {
      margin-top: 0;
      @include pxRem(margin-bottom, 290);
    }
  }

  .Polaris-Frame {
    min-height: auto;
    height: 100%;
  }
}
@media screen and (max-width: 1110px) {
  .setting-page {
    .section-body {
      justify-content: flex-start;
    }
  }
}

.item-tooltip {
  .Polaris-Icon {
    &:hover {
      cursor: pointer;
      .Polaris-Icon__Svg {
        fill: map-get($color, green-a);
      }
    }
  }
}
.checkbox-shipping {
  @include pxRem(margin-top, 4);
  @include pxRem(margin-right, 10);

  &.hide {
    visibility: hidden;
  }
}
.item-accesory-sdgs {
  flex: 1 1 0;
  width: 0;
  .accessory-description {
    @include pxRem(height, 28);
    @include pxRem(width, 290);
  }
}
p.gift-tooltip {
  @include pxRem(margin, 5);
}
#inner-html {
  p.gift-tooltip {
    @include pxRem(margin, 5);
  }
}
.blue-text {
  color: map-get($color, blue-a);
}
.highlight-border {
  .tox-tinymce,
  .field-title .Polaris-TextField__Backdrop {
    box-shadow: var(--p-surface-warning) 0px 0px 8px 0px;
    background: var(--p-surface-warning-subdued);
  }
  .tox {
    .tox-toolbar__primary {
      background-color: var(--p-surface-warning-subdued);
    }
    .tox-edit-area__iframe {
      background-color: var(--p-surface-warning-subdued);
    }
  }
}
